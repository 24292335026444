@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html {
  font-family: 'Roboto', sans-serif;
  max-width: 100%;
  letter-spacing: 1.2px;
}

a:hover {
  text-decoration: underline !important;
  -webkit-text-decoration-color: #6fff43 !important;
          text-decoration-color: #6fff43 !important;
}

header::after {
  content: '';
  display: table;
  clear: both;
}

.title-logo {
  width: 100%;
}

@media (orientation: landscape) {
  .title-logo {
    height: 100%;
    margin: 0 auto;
    width: 100%;
  }
}

@media (min-width: 1025px) {
  .title-logo {
    display: block;
  }
}

@media (min-width: 1025px) {
  .title-logo img {
    height: 5em;
    width: auto;
    float: left;
  }
}

header h3 {
  display: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 3em;
  margin: 0.3em;
  float: left;
  color: black;
}

@media (min-width: 1025px) {
  header h3 {
    display: block;
  }
}

.page-title :hover {
  border: 2px solid red;
}

.container {
  margin: 0 auto;
}

.nav {
  display: none;
}

@media (min-width: 1025px) {
  .nav {
    display: block;
    float: right;
    margin-right: 15px;
    font-size: 1.7em;
  }
}

.nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav ul :last-child {
  margin-right: 5px;
}

@media (min-width: 1025px) {
  .nav li {
    display: inline-block;
    margin-left: 35px;
    margin-right: 35px;
    padding-top: 25px;
  }
}

.nav a {
  color: inherit;
  text-decoration: none;
}

.nav a ::visited {
  color: black;
}

.nav a ::link {
  color: black;
}

.nav a ::hover {
  color: #6fff43;
}

img {
  max-width: 100%;
}

.selected {
  color: black;
  text-decoration: underline !important;
  -webkit-text-decoration-color: #6fff43 !important;
          text-decoration-color: #6fff43 !important;
}

.footer {
  margin: 0 auto;
}

.footer :last-child {
  margin-bottom: 2em;
}

.footer .images {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.footer p {
  text-align: center;
  font-size: 1em;
  margin: 0 auto;
  padding: 2em 3em;
  margin: 1em;
}

@media (min-width: 1025px) {
  .footer {
    width: 85%;
    border-top: 2px solid black;
    margin: 0 auto;
    text-align: center;
    margin-top: 4em;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    padding: 2em;
    padding-bottom: 0.5em;
    padding-top: 0;
  }
  .footer .images {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: 1025px) and (min-width: 1025px) {
  .footer .images {
    width: 90%;
  }
}

@media (min-width: 1025px) {
  .footer p {
    padding: 1em;
  }
}

.footer-sponsors {
  grid-column: span 1;
}

.footer-sponsors p {
  width: 60%;
  margin: 1em auto;
}

@media (min-width: 1025px) {
  .desktop-sponsors-grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
  .desktop-sponsors-grid a {
    height: 100%;
  }
  .desktop-sponsors-grid img {
    padding: 0;
    margin: 0;
  }
}

.footer-menu {
  display: none;
}

@media (min-width: 1025px) {
  .footer-menu {
    display: block;
    grid-column: span 1;
    padding: 1em;
  }
}

.footer-nav li {
  display: block;
}

.footer-nav a {
  color: inherit;
  text-decoration: none;
}

.footer-nav a ::visited {
  color: black;
}

.footer-nav a ::link {
  color: black;
}

.footer-nav a ::hover {
  color: #6fff43;
}

.footer-contact {
  text-align: center;
}

@media (min-width: 1025px) {
  .footer-contact {
    grid-column: span 1;
    text-align: center;
    font-size: 1em;
    margin: 0 auto;
    padding: 1em;
  }
}

.footer-contact a {
  color: black;
}

.footer-contact div {
  padding-bottom: 0;
}

@media (min-width: 1025px) {
  .content {
    padding-top: 2em;
    width: 90%;
    margin: 0 auto;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2em;
    gap: 2em;
    grid-row-gap: 4em;
    row-gap: 4em;
  }
}

.poem {
  text-align: center;
  font-size: 1.5em;
}

@media (orientation: landscape) {
  .poem {
    display: none;
  }
}

@media (min-width: 1025px) {
  .poem {
    display: block;
    font-size: 2vw;
    padding: 1em;
    padding-top: 1.5em;
    line-height: 2.5em;
  }
}

.home-text {
  text-align: justify;
  width: 90%;
  margin: 0 auto;
  font-size: 1.2em;
}

@media (min-width: 1025px) {
  .home-text {
    margin: 0 auto;
    font-size: 1em;
    grid-column: span 1;
    text-justify: auto;
    text-align: justify;
  }
  .home-text :first-child {
    margin-top: 0;
  }
}

.about-text {
  text-align: justify;
  width: 90%;
  margin: 0 auto;
  font-size: 1.2em;
}

@media (min-width: 1025px) {
  .about-text {
    margin: 0 auto;
    font-size: 1em;
    grid-column: span 1;
    text-justify: auto;
    text-align: justify;
    padding: 1.5em;
    padding-top: 0;
    padding-left: 0;
    line-height: 1.5em;
    padding-right: 1.5em;
  }
  .about-text :first-child {
    margin-top: 0;
  }
}

.caption {
  display: none;
}

@media (min-width: 1025px) {
  .caption {
    display: block;
  }
}

@media (orientation: landscape) {
  .logo img {
    display: block;
    line-height: 0;
    max-height: 100vh;
    margin-left: auto;
    margin-right: auto;
  }
}

.poem-landscape {
  display: none;
}

@media (orientation: landscape) {
  .poem-landscape {
    vertical-align: top;
    text-align: center;
    font-size: 1.5em;
    line-height: 2em;
    display: block;
  }
}

@media (min-width: 1025px) {
  .poem-landscape {
    display: none;
  }
}

.home-img-wrapper {
  padding: 0;
  margin: 0;
}

@media (min-width: 1025px) {
  .home-img-wrapper {
    grid-column: span 2;
  }
}

.home-img {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
}

@media (min-width: 1025px) {
  .map {
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
  }
}

.about-img-wrapper {
  grid-column: span 2;
}

.about-img-wrapper h4 {
  font-weight: 300;
  padding: 0;
  margin: 0;
}

@media (min-width: 1025px) {
  .about-img-wrapper {
    padding: 0;
    padding-left: 1.5em;
  }
}

.about-img {
  margin: 0 auto;
  width: 100%;
}

.donate-content {
  margin-top: 4em;
  margin-bottom: 4em;
}

@media (min-width: 1025px) {
  .donate-content {
    width: 80%;
    padding-top: 2em;
    margin: 0 auto;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
}

.donate-img {
  margin-bottom: 4em;
}

@media (min-width: 1025px) {
  .donate-img {
    grid-row: span 3;
    grid-column: span 2;
    width: 80%;
    margin-left: 25%;
  }
}

.donate-text {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  font-size: 1.2em;
}

@media (orientation: landscape) {
  .donate-text {
    width: 60%;
  }
}

@media (min-width: 1025px) {
  .donate-text {
    padding: 1em 2em 0;
    text-align: center;
    grid-column: span 1;
    margin: 0 auto;
    width: 90%;
  }
}

.donate-button {
  text-align: center;
}

.donate-button :focus {
  outline: none;
}

.donate-button-element {
  border-width: 2px;
  margin-top: 1em;
  border-color: #6fff43;
  padding: 15px 32px;
  text-align: center;
  font-size: 1.2em;
  border-radius: 8px;
}

.donate-button-element:hover {
  color: white;
  background-color: #6fff43;
  transition: background-color 0.5s;
}

.donate-pledge {
  grid-column: span 2;
}

.donate-pledge p {
  text-align: justify;
}

.content-projects {
  text-align: justify;
  width: 90%;
  font-size: 1.2em;
  margin: 0 auto;
}

.content-projects img {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
}

.content-projects h3 {
  text-align: left;
}

@media (min-width: 1025px) {
  .content-projects {
    padding-top: 2em;
    font-size: 1em;
    width: 85%;
    margin: 0 auto;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 6em;
    gap: 6em;
  }
  .content-projects h3 {
    text-align: left;
  }
  .content-projects p {
    font-size: 1em;
    grid-column: span 1;
    text-justify: auto;
    text-align: justify;
  }
}

.project1 h3 {
  text-align: center;
}

@media (min-width: 1025px) {
  .project1 {
    grid-column: span 1;
    width: 100%;
    margin: 0 auto;
  }
  .project1 h3 {
    padding-top: 0em;
    text-align: left;
  }
  .project1 div {
    margin-top: 2em;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    grid-gap: 1em;
    gap: 1em;
    margin: 0 auto;
    align-items: center;
  }
}

.project2 h3 {
  padding-top: 1em;
  text-align: center;
}

.project2 iframe {
  width: 100%;
  display: block;
  margin: 0 auto;
}

@media (min-width: 1025px) {
  .project2 {
    grid-column: span 2;
    grid-row: span 1;
    width: 100%;
    margin: 0 auto;
  }
  .project2 h3 {
    padding-top: 0em;
    text-align: left;
  }
  .project2 div {
    margin-top: 2em;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    gap: 1em;
    margin: 0 auto;
  }
  .project2 iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
    margin-bottom: 2em;
  }
}

.video-player {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.video-player iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media (min-width: 1025px) {
  .video-player {
    width: 80%;
    padding: 56.25% 0 0 0;
    position: relative;
  }
}

.project-title-centered {
  text-align: center;
  margin-top: 0em;
}

@media (min-width: 1025px) {
  .project-title-centered {
    margin-left: 10%;
    margin-top: 2em;
  }
}

@media (min-width: 1025px) {
  .project-text-centered {
    width: 80%;
    margin: 0 auto;
    margin-bottom: -2em;
  }
}

.contact-form {
  text-align: center;
}

.content-contact {
  width: 40%;
  margin: 0 auto;
}

.content-contact form {
  border: 2px solid black;
  margin: 5em 5em 0;
  padding: 1em;
}

.mobile-titles {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  margin: 2em;
  text-align: center;
}
/*# sourceMappingURL=App.css.map */
